













































import { Component, PropSync, Vue } from 'vue-property-decorator';

import StepSuccessWealthViewModel
  from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-wealth/step-success-wealth-view-model';

@Component({ name: 'StepSuccessWealth' })
export default class StepSuccessWealth extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  step_success_wealth_model = Vue.observable(
    new StepSuccessWealthViewModel(),
  );
}
